angular.module('enervexSalesappApp').controller('ConfirmManualItemsCtrl', function($scope, $uibModalInstance, manualItems, _) {
	$scope.manualItems = manualItems
	$scope.manualItemCount = 0

	_.each(manualItems, function(i){
		for (x = 1; x <= i.quantity; x++) {
			$scope.manualItemCount++
		}
	})

	$scope.setName = function(item) {
		if (item.itemType == 'existing') {
			return item.product.name
		} else {
			return item.customProduct.name
		}
	}

	$scope.closeModal = function(removeItems, showBOM) {
		var modalInstance = $uibModalInstance.close({
			removeItems: removeItems,
			showBOM: showBOM ? showBOM : false
		});	
	}
});
